import * as React from "react";
import PropTypes from "prop-types";

function populateList(source) {
  return source.map(item => <p key={item.toString()}>{item}</p>);
}

const SimpleText = ({ source, record = {} }) => (
  <div style={{ fontSize: "0.875rem" }}>{populateList(record[source])}</div>
);

SimpleText.defaultProps = {
  addLabel: true,
  label: ""
};

SimpleText.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string
};

export default SimpleText;
