import * as React from "react";

// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Filter,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  ShowButton,
  DeleteButton,
  BooleanField,
  ArrayField,
  SingleFieldList,
  ChipField,
  downloadCSV
} from "react-admin";

import SimpleText from "./SimpleText";

const ProjectExport = (records, fetchRelatedRecords) => {
  fetchRelatedRecords(records, "id", "projectType").then(record => {
    let jsonexport = require("jsonexport");
    const data = records.map(({ id, ...records }) => records);
    let options = {
      verticalOutput: true,
      headers: [
        "email",
        "name",
        "telephone",
        "distinctProject",
        "existingTeam",
        "knowMore",
        "consultancy",
        "designer",
        "developer",
        "leader",
        "howLong",
        "priceRange",
        "skills.value",
        "staffMembers",
        "Remote",
        "startDate",
        "companySize",
        "hours",
        "notes",
        "permanantHire"
      ],
      rename: [
        "Email Address",
        "Name",
        "Phone Number",
        "Distinct Project?",
        "Support Existing Team?",
        "Know more about Flexatal?",
        "Consultancy?",
        "Designer required?",
        "Developer required?",
        "Leader required",
        "Contract Length (weeks)",
        "Budget",
        "Skills Required",
        "Number of Hires",
        "Remote OK?",
        "Start Date",
        "Size of company",
        "Required working hours (p/w)",
        "Notes",
        "Permanent Hire?"
      ]
    };

    jsonexport(data, options, function(err, csv) {
      if (err) return console.log(err);
      console.log("RAW DATA", data);
      console.log("CSV DATA", csv);
      downloadCSV(csv, "project_proposals");
    });
  });
};

const ProjectTitle = ({ record }) => {
  return <span>Project {record ? `${record.email}` : ""}</span>;
};

const ProjectFilter = props => (
  <Filter {...props}>
    <TextInput label="Filter by start date" source="startDate" alwaysOn />
  </Filter>
);

export const ProjectList = props => (
  <List
    {...props}
    exporter={ProjectExport}
    title="Projects"
    filters={<ProjectFilter />}
    sort={{ field: "startDate", order: "ASC" }}
  >
    <Datagrid>
      <TextField source="name" label="Name" />
      <TextField source="Remote" label="Remote OK?" />
      <TextField source="howLong" label="Duration (weeks)" />
      <TextField source="startDate" label="Start Date" />
      <TextField source="staffMembers" label="Number of hires" />
      <ShowButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
);

export const ProjectShow = props => (
  <Show title={<ProjectTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="General">
        <SimpleText source="priceRange" label="Budget (range)" />
        <TextField source="howLong" label="Duration (weeks)" />
        <TextField source="startDate" />
        <TextField source="staffMembers" label="Number of hires" />
        <BooleanField source="permanantHire" label="Permanent position" />
        <TextField source="hours" label="Required working hours" />
        <TextField source="Remote" label="Remote OK?" />
      </Tab>
      <Tab label="Contact Info">
        <TextField source="name" label="Name" />
        <TextField source="email" label="Email" />
        <TextField source="telephone" label="Phone Number" />
        <TextField source="companySize" label="Size of Company" />
      </Tab>
      <Tab label="Roles Required">
        <BooleanField source="designer" label="Looking to hire a designer?" />
        <BooleanField source="developer" label="Looking to hire a developer?" />
        <BooleanField source="leader" label="Looking to hire a leader?" />
      </Tab>
      <Tab label="Skills Required">
        <ArrayField source="skills">
          <SingleFieldList>
            <ChipField source="value" />
          </SingleFieldList>
        </ArrayField>
      </Tab>
      <Tab label="Additional Information ">
        <TextField source="notes" label="Additional Information" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
