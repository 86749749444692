import * as React from "react";

// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Filter,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  ShowButton,
  DeleteButton,
  ArrayField,
  SingleFieldList,
  ChipField,
  downloadCSV
} from "react-admin";
import SimpleText from "./SimpleText";

const FreelancerExport = (records, fetchRelatedRecords) => {
  fetchRelatedRecords(records, "id", "name").then(record => {
    let jsonexport = require("jsonexport");
    const data = records.map(
      ({ id, role, username, publish, created, password, ...records }) =>
        records
    );
    let options = {
      verticalOutput: true,
      headers: [
        "name",
        "email",
        "telephone",
        "skills.value",
        "cv",
        "availability",
        "gitHub",
        "linkedIn",
        "Gdpr",
        "share"
      ],
      rename: [
        "Name",
        "Email Address",
        "Phone Number",
        "Skills",
        "CV Download Link",
        "Available From",
        "GitHub Profile",
        "LinkedIn Profile",
        "Gdpr Status",
        "Share Profile Status"
      ]
    };

    jsonexport(data, options, function(err, csv) {
      if (err) return console.log(err);
      console.log(csv);
      downloadCSV(csv, "freelancers");
    });
  });
};

const FreelancerTitle = ({ record }) => {
  return <span>Freelancer: {record ? `${record.name}` : ""}</span>;
};

const FreelancerFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

export const FreelancerList = props => (
  <List
    {...props}
    exporter={FreelancerExport}
    filters={<FreelancerFilter />}
    sort={{ field: "dateRegistered", order: "DSC" }}
  >
    <Datagrid>
      <TextField source="name" label="Name" />
      <TextField source="email" label="Email" />
      <TextField source="availability" label="Available From" />
      <TextField source="dateRegistered" label="Date Registered" />
      <ShowButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
);

export const FreelancerShow = props => (
  <Show title={<FreelancerTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="Main">
        <TextField source="name" label="Name" />
        <TextField source="email" label="Email" />
        <TextField source="telephone" label="Phone Number" />
        <TextField source="availability" label="Available From" />
      </Tab>
      <Tab label="Skills">
        <ArrayField source="skills">
          <SingleFieldList>
            <ChipField label="skills" source="value" />
          </SingleFieldList>
        </ArrayField>
      </Tab>
      <Tab label="Profile">
        <TextField source="gitHub" label="Github Profile" />
        <TextField source="linkedIn" label="LinkedIn Profile" />
      </Tab>
      <Tab label="Policy">
        <TextField source="Gdpr" label="GDPR Status" />
        <TextField source="share" label="Share Profile Status" />
      </Tab>
      <Tab label="CV">
        <TextField source="cv" label="CV Download Link" />
        <TextField source="fileName" label="CV File Name" />
      </Tab>
      <Tab label="Hourly Rate">
        <SimpleText source="onSiteRate" label="Hourly Rate (on site)" />
        <SimpleText source="remoteRate" label="Hourly Rate (remote)" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
