import * as React from "react"

// tslint:disable-next-line:no-var-requires
import {
	Datagrid,
	List,
	Filter,
	TextField,
	TextInput,
	DeleteButton,
	downloadCSV
} from "react-admin"

const ClientExport = (records, fetchRelatedRecords) => {
	fetchRelatedRecords(records, "id", "name").then(record => {
		let jsonexport = require("jsonexport")
		const data = records.map(({ role, username, id, ...records }) => records)
		let options = {
			verticalOutput: true,
			headers: ["email", "name", "created", "telephone"],
			rename: ["Email Address", "Name", "Created At", "Phone Number"]
		}

		jsonexport(data, options, function(err, csv) {
			if (err) return console.log(err)
			console.log(csv)
			downloadCSV(csv, "clients")
		})
	})
}

const ClientFilter = props => (
	<Filter {...props}>
		<TextInput label='Search' source='name' alwaysOn />
	</Filter>
)

export const ClientList = props => (
	<List
		{...props}
		exporter={ClientExport}
		filters={<ClientFilter />}
		sort={{ field: "name", order: "ASC" }}
	>
		<Datagrid>
			<TextField source='name' label='Name' />
			<TextField source='email' label='Email' />
			<TextField source='telephone' label='Phone Number' />
			<DeleteButton label='' redirect={false} />
		</Datagrid>
	</List>
)
