import React from "react";
import { Login, LoginForm } from "react-admin";
const bg = require("./flexBackground.jpg");

const CustomLoginForm = props => (
  <div>
    {console.log("props", props)}
    <div style={{ fontFamily: "monospace", marginLeft: "15px" }} />
    <LoginForm {...props} />
  </div>
);

const CustomLoginPage = props => (
  <Login backgroundImage={bg} loginForm={<CustomLoginForm />} {...props} />
);

export default CustomLoginPage;
