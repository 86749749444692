import * as React from "react"
import { Admin, Resource } from "react-admin"
import {
	FirebaseRealTimeSaga,
	FirebaseDataProvider,
	FirebaseAuthProvider
} from "react-admin-firebase"
import { createMuiTheme } from "@material-ui/core/styles"

import { firebaseConfig as config } from "./FIREBASE_CONFIG"
import CustomLoginPage from "./CustomLoginPage"
import Dashboard from "./Dashboard"

import { FreelancerList, FreelancerShow } from "./Freelancer"
import { ProjectList, ProjectShow } from "./Project"
import { ClientList } from "./Clients"

import ProjectIcon from "@material-ui/icons/AddBox"
import FreelancerIcon from "@material-ui/icons/Create"
import ClientIcon from "@material-ui/icons/People"

const options = {}

const dataProvider = FirebaseDataProvider(config, options)
const authProvider = FirebaseAuthProvider(config, options)
const firebaseRealtime = FirebaseRealTimeSaga(dataProvider, options)

const theme = createMuiTheme({
	palette: {
		//type: "dark" // Switching the dark mode on is a single property value change.
		primary: {
			main: "#232323"
		},
		secondary: {
			main: "#ff0066"
		}
	}
})

class App extends React.Component {
	render() {
		return (
			<Admin
				dashboard={Dashboard}
				theme={theme}
				loginPage={CustomLoginPage}
				customSagas={[firebaseRealtime]}
				dataProvider={dataProvider}
				authProvider={authProvider}
			>
				<Resource
					name='project'
					options={{ label: "Project Proposals" }}
					list={ProjectList}
					show={ProjectShow}
					icon={ProjectIcon}
				/>
				<Resource
					name='freelancer'
					options={{ label: "Freelancers" }}
					list={FreelancerList}
					show={FreelancerShow}
					icon={FreelancerIcon}
				/>
				<Resource
					name='client'
					options={{ label: "Clients" }}
					list={ClientList}
					icon={ClientIcon}
				/>
			</Admin>
		)
	}
}

export default App
