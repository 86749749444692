export const firebaseConfig = {
  apiKey: "AIzaSyBijRpNoHfOxTUbFb8v2bxJReamCPcA95s",
  authDomain: "flexatal.firebaseapp.com",
  databaseURL: "https://flexatal.firebaseio.com",
  projectId: "flexatal",
  storageBucket: "flexatal.appspot.com",
  messagingSenderId: "640001699949",
  appId: "1:640001699949:web:c5f7539cef0d641ea5cf64",
  measurementId: "G-2TDZZRNP0B"
};
