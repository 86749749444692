import React from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { Title } from "react-admin"
import Logo from "./logo.jpg"
import Typography from "@material-ui/core/Typography"

export default () => (
	<Card
		style={{
			paddingTop: 50,
			paddingBottom: 50,
			paddingLeft: 100,
			paddingRight: 100,
			backgroundColor: "rgba(233, 30, 99, 0.2)"
		}}
	>
		<Title title='Welcome to the Flexatal admin panel' />

		<img style={{ height: 200, paddingTop: 50 }} src={Logo} alt='logo' />
		<Typography style={{ fontSize: 36, fontWeight: "bold" }}>Talent Hub </Typography>
		<CardContent>
			<Typography style={{ fontSize: 24, marginBottom: 20 }}>
				Welcome to the Flexatal admin panel. Here you will find all the data of the individuals who
				have interacted with your site. It is split into three main sections,{" "}
				<span style={{ fontWeight: "bold" }}>Project Proposals</span>,{" "}
				<span style={{ fontWeight: "bold" }}>Freelancers</span> and{" "}
				<span style={{ fontWeight: "bold" }}>Clients</span>. Within those sections you will find
				various tabs containing information.
			</Typography>
			<Typography style={{ fontSize: 24, marginBottom: 20 }}>
				To view the different sections and tabs of information you need to click the little eye
				icon. Initially just the main information is shown so you can get a quick overview of the
				record. You can delete records within this interface. This maybe helpful to you to keep tabs
				on projects once you have dealt with them, whilst still keeping a database of all clients
				and freelancers that you have worked with.
			</Typography>
		</CardContent>
	</Card>
)
